<!-- 礼品卡密码copy -->
<template>
  <div class="copy-card">
    <div class="copy-card__copy-info">
      <p class="copy-card__card-num">
        {{ shareLanguage.SHEIN_KEY_PWA_21903 }}:
        <span :class="['copy-card__card-text', !hiddenCardNum && 'ellipsis']">
          {{ cardCodeText }}
        </span>
        <span
          class="copy-card__eyes"
          @click="toggleCardNumShow"
        >
          <Icon
            v-show="hiddenCardNum"
            name="sui_icon_eyeclosed_20px"
            size="16"
          />
          <Icon
            v-show="!hiddenCardNum"
            name="sui_icon_eyeopen_20px"
            size="16"
          />
        </span>
      </p>
      <p class="copy-card__password">
        {{ shareLanguage.SHEIN_KEY_PWA_15847 }}:
        <span :class="['copy-card__password-text', !hiddenPassword && 'ellipsis']">
          {{ passwordText }}
        </span>
        <span
          class="copy-card__eyes"
          @click="togglePasswordShow"
        >
          <Icon
            v-show="hiddenPassword"
            name="sui_icon_eyeclosed_20px"
            size="16"
          />
          <Icon
            v-show="!hiddenPassword"
            name="sui_icon_eyeopen_20px"
            size="16"
          />
        </span>
      </p>
    </div>

    <button
      class="copy-card__copy-btn"
      @click="handlerCopyClick"
    >
      <span>{{ shareLanguage.SHEIN_KEY_PWA_25296 }}</span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ClipboardJS from 'clipboard'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { Icon } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'ShareCopyCard',
  inject: [
    'sceneData',
    'extendData',
    'shareLanguage',
    'context'
  ],
  components: {
    Icon
  },
  props: {
    // 卡号
    cardCode: {
      type: String,
      default: '',
    },
    // 卡密
    cardCipher: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hiddenPassword: true,
      hiddenCardNum: true,
      clipboard: null,
    }
  },
  computed: {
    passwordText() {
      return this.hiddenPassword && this.cardCipher
        ? '*'.repeat(this.cardCipher.length)
        : this.cardCipher
    },
    cardCodeText() {
      return this.hiddenCardNum && this.cardCode
        ? '*'.repeat(this.cardCode.length)
        : this.cardCode
    },
  },
  methods: {
    togglePasswordShow() {
      this.hiddenPassword = !this.hiddenPassword
    },
    toggleCardNumShow() {
      this.hiddenCardNum = !this.hiddenCardNum
    },
    handlerCopyClick() {
      const clipboard = new ClipboardJS('.copy-card__copy-btn', {
        text: () =>
          // `${this.shareLanguage.SHEIN_KEY_PWA_21903}: ${this.cardCode}, ${this.shareLanguage.SHEIN_KEY_PWA_15847}: ${this.cardCipher}`,
          `${this.cardCode}`,
      })
      clipboard.on('success', (e) => {
        SToast(this.shareLanguage.SHEIN_KEY_PWA_21906)
        e.clearSelection()
      })
      clipboard.on('error', (e) => {
        SToast(this.shareLanguage.SHEIN_KEY_PWA_21907)
        e.clearSelection()
      })
      this.$emit('copy')
    },
  },
})
</script>

<style lang="less" scoped>
.copy-card {
  display: flex;
  justify-content: space-between;
  width: 7.0933rem;
  border-radius: 0.2667rem;
  background-color: #ffffff;
  box-shadow: 0 0 0 0.0267rem #FFCD82 inset;
  overflow: hidden;
  &__copy-info {
    flex: 1;
    min-width: 0;
    text-align: left;
    padding: 0 0.32rem;
  }
  &__card-num,
  &__password {
    height: 0.7733rem;
    line-height: 0.7733rem;
    font-weight: 700;
    color: #222;
    font-size: 0.32rem;
  }

  &__card-num {
    span {
      margin-left: 0.16rem;
    }
  }

  &__password, &__card-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__password-text, &__card-text {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-indent: 0.0533rem;
    margin-left: 0.16rem;
    &.ellipsis {
      text-overflow: ellipsis;
    }
  }

  &__card-num {
    border-bottom: 1px solid #e6e6e6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__eyes {
    // display: inline-flex;
    // align-items: center;
    // width: 15 / @vw;
    // height: 22 / @vw;
    margin-left: 0.2667rem;
  }

  &__copy-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.68rem;
    border: none;
    font-size: 0.3733rem;
    color: #fff;
    font-weight: 700;
    background: linear-gradient(90deg, #9A3F23 0%, #561806 100%);
  }
}
</style>
