<template>
  <div
    class="ccc-teleport"
  >
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { EventBus } from 'public/src/pages/common/event-bus.js'

/**
 * 传送门模拟实现
 * to: 传送门的目标元素 (必传)
 * from: 传送门的源元素 (必传)
 * closeCondition: 关闭传送门的条件
 * changeCondition: 切换传送门的条件
 */
export default defineComponent({
  name: 'Teleport',
  props: {
    to: {
      type: String,
      required: true
    },
    from: {
      type: String,
      required: true,
    },
    /**
     * 关闭传送门的条件
     * @params: options 事件传递的参数
     */
    closeCondition: {
      type: Function,
      default: () => false
    },
    /**
     * 切换传送门的条件
     * @params: vm 传送门实例
     */
    changeCondition: {
      type: Function,
      default: () => (resolve) => resolve?.()
    }
  },
  mounted() {
    EventBus.emit('create-teleport', this)
    EventBus.on('router-change', (router) => {
      if (this.closeCondition?.(router)) {
        this.removeTeleport()
      } else {
        if (!this.hasTeleport()) {
          // 兼容路由回退的情况
          this.openTeleport(this.$el)
        }
      }
    })
    
    if (!this.hasTeleport()) {
      // 首次挂载
      this.openTeleport(this.$el)
    }
  },
  unmounted() {
    // 更换传送门
    EventBus.on('create-teleport', (vm) => {
      new Promise(this.changeCondition(vm)).then(() => {
        // this.removeTeleport() // 节点为引用，更换位置即为移除
        this.openTeleport(vm.$el)
      })
      EventBus.off('create-teleport')
    })
    EventBus.off('router-change')
  },
  methods: {
    hasTeleport() {
      return document.querySelector(`${this.to}>.ccc-teleport`)
    },
    /**
     * 开启传送门，将元素移动到to元素中
     */
    openTeleport(newEl) {
      const toEl = document.querySelector(`${this.to}`)
      toEl.appendChild(newEl)
    },
    /**
     * 关闭传送门，将元素移动到from元素中
     */
    closeTeleport() {
      const fromEl = document.querySelector(`${this.from}`)
      fromEl.appendChild(this.$el)
    },
    /**
     * 移除传送门
     */
    removeTeleport() {
      const lastEl = this.$el
      const toEl = document.querySelector(this.to)
      lastEl && this.hasTeleport() && toEl?.removeChild(lastEl)
    }
  },
})
</script>

<style lang="less">
.ccc-teleport {
  position: relative;
}
</style>
