<template>
  <div class="dialog-point">
    <div class="dialog-point__num">
      <OutlineText
        :text="pointInfo.prizeData"
        font-size="88"
        line-height="88"
      />
      <OutlineText
        :text="`${language.SHEIN_KEY_PWA_25742}`"
        font-size="36"
        line-height="40"
        class="point-text"
      />
    </div>
    <div class="dialog-point__exchange">
      ≈{{ pointExchangePrice }}
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import OutlineText from 'public/src/pages/ccc-campaigns/components/common/OutlineText.vue'
export default defineComponent({
  name: 'PointDialogContent',
  components: {
    OutlineText,
  },
  inject: ['language'],
  props: {
    pointInfo: {
      type: Object,
      default: () => {},
    },
    pointExchangePrice: {
      type: String,
      default: '',
    },
  },
})
</script>
<style lang="less" scoped>
.dialog-point {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  &__num {
    display: flex;
    align-items: baseline;
  }
  &__exchange {
    color: #9F4200;
    font-size: 0.5867rem;
    font-weight: 700;
    text-align: center;
    line-height: 1;
  }
}
</style>
