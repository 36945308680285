<template>
  <div class="share-and-like">
    <ThumbsUp v-if="isOpenLike" />
    <Share v-if="isOpenShare" />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import ThumbsUp from './components/ThumbsUp.vue'
import Share from './components/share/index.vue'

export default defineComponent({
  name: 'ShareAndLike',
  components: {
    ThumbsUp,
    Share,
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
    sceneData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    extendData() {
      return JSON.parse(this.context?.content?.extend || '{}')
    },
    isOpenLike() {
      return this.extendData?.isOpenLike || false
    },
    isOpenShare() {
      return this.extendData?.isOpenShare || false
    },
    showBottomTab() {
      return this.context.content?.bottomTabConfig?.length >= 3
    },
  },
  provide() {
    const { share: shareLanguage = {} } = this?.context?.language || {}
    return {
      context: this.context,
      sceneData: this.sceneData,
      extendData: this.extendData,
      shareLanguage,
    }
  },
})
</script>
<style lang="less" scoped>
.share-and-like {
  .flexbox();
  .align-center();
  flex-direction: column;
  position: fixed;
  width: 1.98rem;
  right: calc(50% - 5rem);
  top: 48%;
  // bottom: calc(var(--scoll-to-top-bottom) + 1.333rem);
  z-index: 	@zindex-list-hdfilter; // 98
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
