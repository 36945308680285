<template>
  <div
    :class="['suspension-countdown', { 'suspension-countdown-romwe': isRomwe }]"
  >
    <span class="suspension-countdown__time">{{ tiemObj.H }}</span>
    <span class="suspension-countdown__colon">:</span>
    <span class="suspension-countdown__time">{{ tiemObj.M }}</span>
    <span class="suspension-countdown__colon">:</span>
    <span class="suspension-countdown__time">{{ tiemObj.S }}</span>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { CountDown } from '@shein/common-function'

export default defineComponent({
  name: 'ModalsSuspensionCountdown',
  props: {
    bindTime: {
      type: Number,
      default: 0,
    },
    dynamicExpiredDate: {
      type: Number,
      default: 0,
    },
    endTime: {
      type: Number,
      default: 0,
    },
    startTime: {
      type: Number,
      default: 0,
    },
    isRomwe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tiemObj: {},
      timer: null,
    }
  },
  mounted() {
    nextTick(() => {
      this.countdown()
    })
  },
  methods: {
    countdown() {
      const currentTime = Math.ceil(new Date().getTime() / 1000) // 转化成秒
      const dynamicTime = this.dynamicExpiredDate * 24 * 60 * 60
      let remainTime = 0
      if (this.dynamicExpiredDate) {
        remainTime = this.bindTime + dynamicTime - currentTime
      } else {
        remainTime = this.endTime - currentTime
      }
      const self = this
      const timer = new CountDown({
        seconds: remainTime - 1,
        countFunc(tiemObj) {
          self.tiemObj = tiemObj
          const { H = '', M = '', S = '' } = tiemObj
          if (H === '00' && M === '00' && S === '00') self.$emit('close')
        },
      })
      timer.start()
    },
  },
})
</script>

<style lang="less" scoped>
.suspension-countdown {
  display: flex;
  align-items: center;
  margin-left: 0.3733rem;
  &__time {
    background: #F33021;
    color: #fff;
    height: 0.4533rem;
    width: 0.4533rem;
    padding: 0 0.04rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.3733rem;
    font-weight: 400;
    border-radius: 0.04rem;
  }
  &__colon {
    padding: 0 0.08rem;
    font-size: 0.3733rem;
    font-weight: 400;
    color: #F33021;
  }
}
.suspension-countdown-romwe {
  .suspension-countdown__time {
    background: #FC416E;
  }
}
</style>
