<template>
  <ClientOnly>
    <div
      class="rules-dialog"
    >
      <div
        class="rules-dialog__icon"
        :class="{
          'rules-dialog__icon--left': rulesData.placement === 'left',
        }"
        @click="showDrawer = !showDrawer"
      >
        <span
          v-expose="{id: '2-22-33'}"
          v-tap="{id: '2-22-34'}"
          class="rules-dialog__icon-btn"
          :style="{
            color: rulesData.color,
            backgroundColor: rulesData.backgroundColor,
            borderColor: rulesData.backgroundColor,
          }"
        >
          {{ language.SHEIN_KEY_PWA_14665 }}
        </span>
      </div>
      <SLazyMount>
        <s-drawer
          v-model:visible="showDrawer"
          :direction="'btt'"
          :type="'multi'"
          :closeIconStyle="{
            color: '#000',
            fontSize: '15px',
          }"
        >
          <!-- 标题 S -->
          <template #title>
            <div class="rules-dialog__title">
              {{ language.SHEIN_KEY_PWA_14665 }}
            </div>
          </template>
          <!-- 标题 E -->

          <!-- 内容 S -->
          <div class="rules-dialog__content">
            <p
              v-for="(item, index) in rulesData.items"
              :key="index"
              class="rules-dialog__content-item"
            >
              {{ rulesData.langValueMap?.[rulesData.langKeyMap?.[item.textLangRefKey]?.pwa] || '' }}
            </p>
          </div>
        <!-- 内容 E -->
        </s-drawer>
      </SLazyMount>
    </div>
  </ClientOnly>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { ClientOnly } from '@sheinfe/vue-client-only'

export default defineComponent({
  name: 'RulesDialog',
  directives: {
    expose,
    tap,
  },
  components: {
    ClientOnly,
    SLazyMount,
    SDrawer,
  },
  props: {
    context: {
      type: Object,
      default: () => {},
    },
    sceneData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDrawer: false
    }
  },
  computed: {
    // enableRule() {
    //   return this.rulesData.enable
    // },
    rulesData() {
      const extend = this.context?.content?.extendParse
      return extend?.rule || {}
    },
    language() {
      return this.context?.language || {}
    },
  },
  watch: {
    showDrawer(val) {
      if (val) {
        daEventCenter.triggerNotice({
          daId: '2-22-35',
        })
      }
    }
  },
})
</script>

<style lang="less">
  /* stylelint-disable selector-class-pattern, selector-nested-pattern */
  .rules-dialog {
    background-color: #fff;
    &__icon {
      position: absolute;
      right: 0;
      top: .32rem;
      z-index: @zindex-header;
      &--left {
        left: 0;
        right: auto;
        .rules-dialog__icon-btn {
          border-radius: 0rem .213rem .213rem 0rem;
        }
      }
    }
    &__icon-btn {
      font-family: SF UI Display;
      text-align: center;
      padding: 0 .213rem 0 .213rem;
      height: .587rem;
      line-height: .587rem;
      border-radius: .32rem 0rem 0rem .32rem;
      border: .013rem solid rgba(255, 255, 255, 0.70);
      background: rgba(0, 0, 0, 0.10);
      color: #FFF;
      font-size: .32rem;
      font-weight: 500;
    }
    &__title {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: .613rem;
      font-weight: 700;
      text-transform: capitalize;
    }
    &__content {
      font-family: SF UI Text;
      padding: 0 .64rem 1.493rem .64rem;
      background-color: #fff;
      color: #000;
      font-size: .373rem;
      font-weight: 400;
      line-height: .52rem;
      min-height: 8rem;
    }
    &__content-item {
      margin-bottom: 12px;
      word-break: break-word;
    }

    // 覆盖Sui组件样式
    .sui-drawer__header_normal {
      border-bottom: none;
      line-height: 1;
      padding: .64rem .32rem;
    }
    .sui-drawer__container {
      border-radius: 0.32rem 0.32rem 0rem 0rem;
    }
    .sui-drawer__close-btn {
      top: 0.25rem;
      right: 0.25rem;
    }
  }
</style>
