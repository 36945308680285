import { asyncLoadFile } from '@shein/common-function'
const { PUBLIC_CDN, SERVER_TYPE, FM_UPGRADE } = gbCommonInfo

const configs = {
  central: { appName: 'shein_central_web', partner: 'shein_central', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.us.3.9.4.js` },
  usa: { appName: 'shein_us_web', partner: 'shein_us', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.us.3.9.4.js` },
  eur: { appName: 'shein_eur_web', partner: 'shein_eur', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.eur.3.9.4.js` },
}

const latest_configs = {
  central: { appName: 'shein_central_web', partner: 'shein_central', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.us.4.0.4.js`, normal: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/normal.us.4.0.4.js` },
  usa: { appName: 'shein_us_web', partner: 'shein_us', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.us.4.0.4.js`, normal: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/normal.us.4.0.4.js` },
  eur: { appName: 'shein_eur_web', partner: 'shein_eur', src: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/fm.eur.4.0.4.js`, normal: `${PUBLIC_CDN}/pwa_dist/libs/risk_libs/normal.eur.4.0.4.js` },
}

let config = configs[SERVER_TYPE] || configs.central
let latest_config = latest_configs[SERVER_TYPE] || latest_configs.central
config = FM_UPGRADE === 'on' ? latest_config : config
let blackboxPromise = null
let pending = null
export const __blackbox = new Promise((res) => { blackboxPromise = res })
export async function loadTdLibJs() {
  if (typeof window === 'undefined') {
    return
  }
  /**
   * 同盾js，风控端要求接入
   */
  window._fmOpt = window._fmOpt || FM_UPGRADE === 'on' ?
    {
      partner: config.partner,
      appName: config.appName,
      interfaceProtection: true,
      normalUrl: config.normal,
      token: config.appName + '-' + new Date().getTime() + '-' + Math.random().toString(16).substr(2),
      is_retry: false,
      collectBehavior: true,
      success: (v) => {
        window._fmOpt.__blackbox = v
        blackboxPromise(v)
      },
      error: () => {
        blackboxPromise('')
      }
    }
    : {
      partner: config.partner,
      appName: config.appName,
      token: config.appName + '-' + new Date().getTime() + '-' + Math.random().toString(16).substr(2),
      is_retry: false,
      collectBehavior: true,
      success: (v) => {
        window._fmOpt.__blackbox = v
        blackboxPromise(v)
      },
      error: () => {
        blackboxPromise('')
      }
    }
  const { ATO_SDK_SWITCH } = window.SHEIN_LOGIN?.__pre_data || {}
  if (ATO_SDK_SWITCH == 'OFF') return
  if (pending || window._fmOpt.load_success) return
  if (window._fmOpt.is_retry) return
  if (window._fmOpt.__blackbox) return
  const src = config.src
  pending = asyncLoadFile({ label: 'script', attrs: { async: true, src } }).then(() => {
    pending = null
    // 记录sdk加载成功
    window._fmOpt.load_success = true
  }).catch(() => {
    pending = null
    loadTdLibJs()
    window._fmOpt.is_retry = true
  })
  await pending
}
