<template>
  <SLazyMount>
    <s-drawer
      v-expose="getAnalysisData('2-22-29', snsExposeReportParams)"
      v-model:visible="visible"
      :append-to-body="true"
      :show-close="false"
      class="share-drawer"
      @close="closeDrawer"
    >
      <!-- 弹窗上面的装饰图片 -->
      <template
        v-if="extendData.isOpenAtmosphere && shareType !== 2"
        #title
      >
        <div class="share-drawer__top">
          <img
            :src="images.topAtmosphere"
            alt=""
          />
        </div>
      </template>
      <!-- 弹窗上面的抽奖内容，未抽奖 -->
      <template
        v-if="shareType === 2 && !shareStatus && !isAllPrizeDrawOut"
        #title
      >
        <LotteryContent
          :show="show"
          :share-info="shareInfo"
          @close="closeDrawer"
        />
      </template>
      <!-- 弹窗背景 -->
      <div
        class="share-drawer__bg"
        :style="bgStyle"
      ></div>
      <!-- 弹窗右上角的关闭按钮，只有有奖分享、普通分享、抽奖分享 && 已经抽奖 三种情况才展示-->
      <div
        v-if="!(shareType === 2 && !shareStatus && !isAllPrizeDrawOut)"
        class="share-drawer__close-icon"
        @click="closeDrawer"
      >
        <Icon
          name="sui_icon_close_16px_2"
          size="20px"
          color="#222"
        />
      </div>
      <!-- 弹窗标题，只有有奖分享、普通分享、抽奖分享 && 已经抽奖 三种情况才展示-->
      <div
        v-if="!(shareType === 2 && !shareStatus && !isAllPrizeDrawOut)"
        class="share-drawer__title"
        :style="{ color: themeColor }"
      >
        {{ shareLanguage.SHEIN_KEY_PWA_25733 }}
      </div>
      <!-- 有奖分享 -->
      <Banner
        v-if="shareType === 1"
        :share-info="shareInfo"
      />
      <!-- 抽奖面板，已抽奖 -->
      <LotteryBanner
        v-if="shareType === 2 && shareStatus"
        :user-win-prize="userWinPrize"
        @close="closeDrawer"
      />
      <ChannelList
        :sns-list="snsList"
        :system-list="systemList"
        @snsClick="handlerChannelShare"
        @systemClick="handlerSystemShare"
      />
    </s-drawer>
  </SLazyMount>
</template>
<script>
import { defineComponent } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import Banner from './Banner.vue'
import ChannelList from './ChannelList.vue'
import LotteryContent from './LotteryContent.vue'
import LotteryBanner from './LotteryBanner.vue'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'
import { Icon } from '@shein-aidc/icon-vue3'
import { isNumber } from '@shein/common-function'
// import { isIOS } from 'public/src/pages/components/ccc/common/utils.js'
export default defineComponent({
  name: 'ShareDrawer',
  inject: ['sceneData', 'extendData', 'shareLanguage'],
  components: {
    Banner,
    ChannelList,
    SDrawer,
    Icon,
    SLazyMount,
    LotteryContent,
    LotteryBanner,
  },
  directives: {
    tap,
    expose,
  },
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    shareInfo: {
      type: Object,
      default: () => ({}),
    },
    shareChannel: {
      type: Object,
      default: () => ({
        firstLine: [],
        secondLine: [],
      }), 
    }
  },
  data() {
    return {
      visible: false,
      images,
      shareCode: '', // 分享码
      shareParams: {}, // 分享参数
    }
  },
  computed: {
    themeColor() {
      return this.extendData?.color || '#FF7629'
    },
    bgStyle() {
      const colorRgba = (o) => hexToRGBA(this.themeColor, o)
      return {
        background: `linear-gradient(180deg, ${colorRgba(0.15)} 0%, ${colorRgba(
          0
        )} 100%)`,
      }
    },
    snsList({ shareChannel }) {
      const snsListMap = {
        'Twitter': {
          type: 5,
          logo: 'sui_img_twitter_58px_color',
          channel: 'twitter',
          name: this.shareLanguage.SHEIN_KEY_PWA_25894,
          originUrl: 'https://twitter.com/intent/tweet?url=',
        },
        'Facebook': {
          type: 4,
          logo: 'sui_img_fb_58px_color',
          channel: 'facebook',
          name: this.shareLanguage.SHEIN_KEY_PWA_25892,
          originUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
        },
        'Pinterest': {
          type: 7,
          logo: 'sui_img_pinterest_58px_color',
          channel: 'pinterest',
          name: this.shareLanguage.SHEIN_KEY_PWA_25895,
          originUrl: 'https://pinterest.com/pin/create/button/?url=',
        },
      }
      const firstLine = []
      shareChannel.firstLine?.length && shareChannel.firstLine.forEach((item) => {
        if (snsListMap[item]) {
          firstLine.push(snsListMap[item])
        }
      })
      // pwa端默认有pinterest分享 && 旧数据按之前顺序不在此push到最后
      if (!(shareChannel.firstLine && shareChannel.firstLine.includes('Pinterest'))) {
        firstLine.push(snsListMap['Pinterest'])
      }
      return firstLine
    },
    systemList({ shareChannel }) {
      const systemListMap = {
        'Copy Link': {
          type: 2,
          icon: 'sui_img_copy_58px',
          channel: 'copyInviteLink',
          name: this.shareLanguage.SHEIN_KEY_PWA_25897,
        },
        'Message': {
          type: 1,
          icon: 'sui_img_message_58px_color',
          channel: 'message',
          name: this.shareLanguage.SHEIN_KEY_PWA_25896,
        },
      }
      const secondLine = []
      shareChannel.secondLine?.length && shareChannel.secondLine.forEach((item) => {
        if (systemListMap[item]) {
          secondLine.push(systemListMap[item])
        }
      })
      return secondLine
      // window.navigator.share兼容度较差，暂时不做more分享面板
      // {
      //   type: 3,
      //   icon: 'sui_img_more_58px',
      //   channel: 'more',
      //   name: this.shareLanguage.SHEIN_KEY_PWA_25898,
      // },
    },
    /**
     * 分享渠道曝光上报参数（sns_type：点击渠道类型）
     * 1为message
     * 2为copy link
     * 3为more
     * 4为fb
     * 5为twitter
     * 6为whatsapp
     * 7为pinterest
     * 8为line
     * */
    snsExposeReportParams() {
      const typeCompose = [...this.snsList, ...this.systemList]
        .map((item) => item.type)
        .join('_')
      return {
        sns_type: typeCompose,
        share_button_from: 1,
      }
    },
    // 分享类型
    shareType() {
      return Number(this.extendData?.shareType || '0')
    },
    // 分享状态 0:未分享 1:已分享
    shareStatus({ shareInfo }) {
      const { shareStatus = 0 } = shareInfo
      return Number(shareStatus)
    },
    // 用户中奖信息
    userWinPrize({ shareInfo }) {
      return shareInfo?.userWinPrize || {}
    },
    // 判断奖品是否全部抽完
    isAllPrizeDrawOut({ shareInfo }) {
      const { prizes = [] } = shareInfo?.prizePoolRuleBO || {}
      // prizeLeftSum为空时表示不限制库存，只有等于0时表示奖品已抽完（类型5为谢谢惠顾视作已抽完）
      return prizes.every(
        item => item.prizeType === 5 || (isNumber(item.prizeLeftSum) && item.prizeLeftSum <= 0)
      )
    },
  },
  watch: {
    show(val) {
      this.visible = val
      if (val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    visible(val) {
      this.$emit('update:show', val)
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close')
    },
    handlerChannelShare({ channel, url }) {
      window.open(url)
      this.$emit('shareResult', {
        channel,
        result: 1,
      })
    },
    handlerSystemShare(result) {
      this.$emit('shareResult', result)
    },
  },
})
</script>
<style lang="less" scoped>
.share-drawer {
  background: rgba(0, 0, 0, .2);
  :deep(.sui-drawer__container) {
    background: transparent;
    box-shadow: none;
    max-height: fit-content !important; /* stylelint-disable declaration-no-important */
  }
  :deep(.sui-drawer__header) {
    background: transparent;
    border-bottom: none;
    position: relative;
    bottom: 0;
  }
  :deep(.sui-drawer__body) {
    border-top-left-radius: 0.4267rem;
    border-top-right-radius: 0.4267rem;
    position: relative;
  }
  &__close-icon {
    text-align: right;
    right: 0.1867rem;
    top: 0.1867rem;
    line-height: 1;
    position: absolute;
  }
  &__top {
    height: auto;
    width: 100%;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__bg {
    width: 100%;
    height: 3.4667rem;
    position: absolute;
    top: 0;
  }
  &__title {
    color: #ff7629;
    text-align: center;
    // font-family: Roboto;
    font-size: 0.56rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 0.64rem;
  }
}
</style>
