import { queryCouponStatusApi, queryCouponDetailAndStatusApi } from 'public/src/services/api/ccc-coupon'
import { getPageConfigDialogPoint, getPointExchangePriceApi, bindPopCouponApi } from 'public/src/services/api/ccc'
// import { template, throttle } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { isIOS, adjustFontSizeSingle, autoResponsiveFontSize } from 'public/src/pages/components/ccc/common/utils.js'
import { SToast } from '@shein-aidc/sui-toast/mobile'

export default {
  mixins: [mixins],
  data() {
    return {
      couponList: [], // 优惠券详情list
      pointExchangePrice: '', // 积分兑换的价格
      availableCouponCodeList: [], // 可用的优惠券
      bindNum: 0, // 绑定次数
      unbindCouponCodeList: [], // 没有被绑定的优惠券
      couponOriginalList: [], // 优惠券原始数据
      leaveNoBindCoupon: false, // 挽留弹窗所有未绑定已过期的优惠券认定为所有已绑定
      bindSuccessCouponList: [] // 绑定成功的券
    }
  },
  watch: {
    show(val) {
      if (val) {
        // 埋点1为积分，2为优惠券
        const saParams = {
          1: '-',
          2: `2_${this.couponCodeList?.join('_')}`,
          3: `1_${this.pointInfo?.prizeCode}_${this.pointInfo?.prizeData}`,
        }
        daEventCenter.triggerNotice({
          daId: this.dialogType === 'entrance' ? '2-22-36' : '2-22-38',
          bindData: {
            prize_type: saParams[this.prizeType],
          }
        })
        if (isIOS) {
          document.body.style.overflow = 'hidden'
        }
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },
  methods: {
    // 计算字体大小
    handleOpened() {
      autoResponsiveFontSize({
        containerEl: this.$refs?.['titleRef'],
        textEl: this.$refs?.['titleRef'],
        minFontSize: 18,
      })
      adjustFontSizeSingle({
        containerEl: this.$refs?.['primaryBtnRef'],
        textEl: this.$refs?.['primaryBtnRef'],
        minFontSize: 14,
      })
      if (this.dialogType === 'leave') {
        autoResponsiveFontSize({
          containerEl: this.$refs?.['subTitleRef'],
          textEl: this.$refs?.['subTitleRef'],
          minFontSize: 12,
        })
      }
    },
    // 获取积分兑换价格
    async getPointExchangePrice() {
      const params = {
        point: this.pointInfo?.prizeData,
        requestType: 'campaignsPageConfigPointExchangePrice'
      }
      try {
        const { data = {}, code = '' } = await getPointExchangePriceApi(params)
        if (code === '1000') {
          this.pointExchangePrice = data?.amountWithSymbol
        }
      } catch(e) {
        console.log(e)
      }
    },
    // 聚合优惠券详情和优惠券状态接口
    async queryCouponDetailAndStatus(flag = 'all') {
      const detailParams = {
        codes: this.couponCodeList,
        requestType: 'couponDetails',
        extra_fields: ['coupon_bind'],
      }
      const statusParams = {
        couponCodeList: this.couponCodeList,
        requestType: 'couponStatus',
        queryUserStatusFlag: true,
        filterBindableFlag: false,
      }
      const result = await queryCouponDetailAndStatusApi({
        detailParams,
        statusParams,
        flag,
      })
      const { detailData = [], statusData = {} } = result || {}
      this.couponOriginalList = detailData
      this.couponList = detailData
      const { code = '', data = {} } = statusData
      if (code === '1000') this._handleCouponStatusData(data)
    },
    // 登录后查询优惠券状态
    async queryCouponStatus(scene = '') {
      const params = {
        couponCodeList: this.couponCodeList,
        requestType: 'couponStatus',
        queryUserStatusFlag: true,
        filterBindableFlag: false,
      }
      try {
        const { code = '', data = {} } = await queryCouponStatusApi(params)
        if (code === '1000') {
          this._handleCouponStatusData(data, scene)
        }
      } catch(e) {
        console.log(e)
      }
    },
    // 处理优惠券状态数据
    _handleCouponStatusData(data = {}, scene = '') {
      // 按B端配置的顺序给状态接口返回的数据重新排序
      let statusData = {}
      this.couponCodeList.forEach(item => {
        Object.keys(data)?.forEach(key => {
          if (item === key.toLowerCase()) statusData[item] = data[item]
        })
      })
      this.unbindCouponCodeList = []
      this.availableCouponCodeList = []
      Object.keys(statusData).forEach(key => {
        const item = statusData[key]
        // bindStatus 绑定状态 0未绑定 1已绑定
        // useStatus 可用状态 0不可用 1可用
        if (item?.bindStatus === 0) {
          this.unbindCouponCodeList.push(key)
        }
        // 全部已绑定但是未使用的券
        if (item?.bindStatus === 1 && item?.useStatus === 1) {
          this.availableCouponCodeList.push(key)
        }
      })
      if (this.unbindCouponCodeList.length) {
        this.couponList = this.couponList.filter(i => this.unbindCouponCodeList.includes(i.couponCode)) || []
        // 有未绑定的优惠券，但是优惠券全部已过期了，详情接口那边不返回数据，挽留弹窗要显示已绑定未领取的券
        if (!this.couponList.length && this.dialogType === 'leave') {
          this.couponList = this.availableCouponCodeList.length && this.couponOriginalList.filter(i => this.availableCouponCodeList.includes(i.couponCode)) || []
          this.leaveNoBindCoupon = true
        }
      } else {
        this.couponList = this.availableCouponCodeList.length && this.couponList.filter(i => this.availableCouponCodeList.includes(i.couponCode)) || []
        this.leaveNoBindCoupon = true
      }
      // 挽留弹窗，没有绑定或者可用的优惠券，不展示弹窗
      if (this.dialogType === 'leave' && !this.unbindCouponCodeList.length && !this.availableCouponCodeList.length) {
        if (scene !== 'loginCb') {
          this.closePage()
        }
      }
    },
    // 绑定领取优惠券
    handleBindCoupon() {
      // 挽留弹窗：优惠券全部已经绑定了，不调接口
      if (!this.unbindCouponCodeList.length && this.dialogType === 'leave' || this.leaveNoBindCoupon) {
        this._commonCloseFn('leave')
        return
      }
      const handleToast = (type) => {
        const enumToast = {
          'got': this.dialogLanguage.SHEIN_KEY_PWA_21379,
          'fail': `${this.unbindCouponCodeList.join(',')} ${this.dialogLanguage.SHEIN_KEY_PWA_26544}`,
          'successful': this.dialogLanguage.SHEIN_KEY_PWA_26543,
        }
        SToast(`${enumToast[type]}`)
        // 全部绑定成功传未绑定的codeList,有绑定成功有绑定失败的情况下传绑定成功的codeList,底部悬浮优先展示绑定的第一张优惠券
        const availableList = this.bindSuccessCouponList.length ? this.bindSuccessCouponList : this.availableCouponCodeList
        this._commonCloseFn('', availableList)
      }
      const successFn = (successList) => {
        if (successList?.length) {
          this.bindSuccessCouponList = successList.map(i => i.couponCode)
        }
      }
      const params = {
        couponCodeList: this.unbindCouponCodeList,
        identity: this.context?.content?.identity,
        popType: this.dialogType === 'entrance' ? 1 : 2,   // 弹窗类型 1钩子弹窗 2挽留弹窗
      }
      bindPopCouponApi(params).then(res => {
        const { code = '', data = {} } = res || {}
        // 新绑券接口券码不在白单中——509901
        // 新绑券接口券码无效——509902
        // 新绑券接口不支持email券 ——509903
        // 新绑券接口不支持MemberId券——509904
        // 新绑券接口不支持不限制券——509905
        // 新绑券接口不是当前站点的券——509906
        // 新绑券接口email已绑定过——509907
        // 新绑券接口member_id已绑定过——509908
        // email类型券绑定次数已经超过可用次数——509909
        // 新绑券接口券不是启用状态——509910
        // 新绑券接口券已过期——509911
        // 券只能运营后台绑定——509912
        // 用户已领取弹窗权益——9010
        if (code == '501301' || code == '300206') {
          this.toLogin()
        } else if (code == '9010') {
          handleToast('got')
        } else if (code === '1000') {
          const { successList = [], failureList = [], } = data
          if (failureList?.length) {
            this.unbindCouponCodeList = failureList.map(i => i.couponCode)
            this.bindNum++
            if (this.bindNum === 1) {
              successFn(successList)
              this.handleBindCoupon()
            } else if (this.bindNum === 2) {
              successFn(successList)
              handleToast('fail')
            }
          } else {
            successFn(successList)
            handleToast('successful')
          }
        } else {
          handleToast('fail')
        }
      }).catch((e) => {
        console.log(e)
        handleToast('fail')
      })
    },
    // 领取积分
    async handleBindPoint() {
      const handleToast = (type) => {
        const enumToast = {
          'got': this.dialogLanguage.SHEIN_KEY_PWA_25740,
          'fail': this.dialogLanguage.SHEIN_KEY_PWA_26546,
          'successful': this.dialogLanguage.SHEIN_KEY_PWA_26545,
        }
        SToast(`${enumToast[type]}`)
        this._commonCloseFn()
      }
      try {
        const { prizeCode = '', prizeData = 0 } = this.pointInfo || {}
        const { code = '' } = await getPageConfigDialogPoint({
          pointTypeId: prizeCode,
          point: prizeData,
          identity: this.context?.content?.identity,
          popType: this.dialogType === 'entrance' ? 1 : 2,   // 弹窗类型 1钩子弹窗 2挽留弹窗
        })
        if (code === '1000') {
          handleToast('successful')
        } else if (code === '9010') { // 用户已领取弹窗权益
          handleToast('got')
        } else if (['0001', '5004'].includes(code)) {
          this.bindNum++
          if (this.bindNum === 1) {
            this.handleBindPoint()
          } else if (this.bindNum === 2) {
            handleToast('fail')
          }
        } else {
          handleToast('fail')
        }
      } catch (error) {
        handleToast('fail')
      }
    },
    // 登录成功回调
    async loginSuccessCb() {
      if (this.prizeType === 2) {
        await this.queryCouponStatus('loginCb')
        let length = 0
        const unbindCouponCodeListLength = this.unbindCouponCodeList.length
        if (this.dialogType === 'entrance') length = unbindCouponCodeListLength
        if (this.dialogType === 'leave') length = unbindCouponCodeListLength || this.availableCouponCodeList.length
        if (!length) {
          SToast(this.dialogLanguage.SHEIN_KEY_PWA_26601)
          this.show = false
          this.$emit('closeDialog', false)
        }
        // 登录成功后只有钩子弹窗在没有未绑定的优惠券并且有可用优惠券的情况下展示底部悬浮栏
        if (this.availableCouponCodeList.length && !this.unbindCouponCodeList.length && this.dialogType === 'entrance') {
          this.$emit('showBottomSuspension', {
            dialogType: 'entrance',
            availableCouponCodeList: this.availableCouponCodeList
          })
        }
      }
    },
    // 去登录
    toLogin() {
      SHEIN_LOGIN.show({
        show: true,
        bi: 'other',
        cb: () => {
          this.loginSuccessCb()
        },
      })
    },
    // 点击主按钮
    handlePrimaryBtn() {
      this.commonAnalysis('primaryBtn')
      if ([2, 3].includes(this.prizeType)) {
        if (!isLogin()) {
          this.toLogin()
          return
        }
        if (this.prizeType === 2) this.handleBindCoupon()
        if (this.prizeType === 3) this.handleBindPoint()
      } else {
        if (this.dialogType === 'entrance') {
          this._commonCloseFn('entrance')
        } else {
          this.handleJumpLink()
        }
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.commonAnalysis('closeIcon')
      this._commonCloseFn()
    },
    _commonCloseFn(dialogType = '', codeList = []) {
      this.show = false
      this.$emit('closeDialog', false)
      this.$emit('showBottomSuspension', {
        dialogType: dialogType || this.dialogType,
        availableCouponCodeList: codeList.length ? codeList : this.availableCouponCodeList
      })
    },
    // 埋点
    commonAnalysis(clickType = 'primaryBtn') {
      const isEntrance = this.dialogType === 'entrance'
      const { jumpType: { hrefTarget = '', hrefType = '' } = {} } = this?.beforeLeaveData || {}
      // 埋点1为积分，2为优惠券
      const saPrizeTypeParams = {
        1: '-',
        2: `2_${this.couponCodeList?.join('_')}`,
        3: `1_${this.pointInfo?.prizeCode}_${this.pointInfo?.prizeData}`,
      }
      const saPopType = {
        'leave': 1,
        'primaryBtn': isEntrance ? 1 : this.prizeType === 1 ? `2_${hrefType}_${hrefTarget}` : 2,
        'closeIcon': isEntrance ? 2 : 3,
      }
      const saPopTypeParams = isEntrance ? { hook_pop_type: saPopType[clickType] } : { leave_pop_type: saPopType[clickType] }
      daEventCenter.triggerNotice({
        daId: isEntrance ? '2-22-37' : '2-22-39',
        bindData: {
          prize_type: saPrizeTypeParams[this.prizeType],
          ...saPopTypeParams,
        }
      })
    }
  },
}
