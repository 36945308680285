<template>
  <ClientOnly>
    <div
      :class="['share-animation', { 'share-animation-gif': !isSupportWebp }]"
    >
      <img
        :src="isSupportWebp ? images.shareAnimation : images.shareAnimationGif"
        alt=""
      />
    </div>
  </ClientOnly>
</template>
<script>
import { defineComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import { preloadImgs } from '@shein/common-function'

export default defineComponent({
  name: 'ShareAnimation',
  components: {
    ClientOnly,
  },
  inject: ['context'],
  data() {
    return {
      images,
    }
  },
  computed: {
    isSupportWebp() {
      const { RESOURCE_SDK = {} } = this.context || {}
      const { isSupportWeb = '' } = RESOURCE_SDK || {}

      return Boolean(isSupportWeb)
    },
  },
  mounted() {
    // 预加动效
    preloadImgs({
      imgs: [this.images.shareAnimation, this.images.shareAnimationGif],
    })
  },
})
</script>
<style lang="less" scoped>
.share-animation {
  position: absolute;
  // margin-left: 0.2933rem;
  margin: 0 auto;
  z-index: -1;
  bottom: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.share-animation-gif {
  margin-left: 0;
}
</style>
