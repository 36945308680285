<template>
  <SLazyMount>
    <s-dialog
      v-expose="getAnalysisData('2-22-31', prizeReportParams)"
      v-model:visible="show"
      :show-close="false"
      :append-to-body="true"
      class="prize-dialog"
    >
      <template #top>
        <div class="prize-dialog__title">
          {{ shareLanguage.SHEIN_KEY_PWA_25735 }}
        </div>
      </template>
      <div
        class="prize-dialog__content"
        :style="{ backgroundImage: `url(${images.prizeDialogBg})` }"
      >
        <!-- 积分 -->
        <div
          v-if="prizeType === 2"
          class="prize-dialog__content-point"
        >
          <OutlineText
            :text="prizeInfo.prizeData"
            font-size="96"
          />
          <OutlineText
            :text="shareLanguage.SHEIN_KEY_PWA_25742"
            font-size="48"
            class="point-text"
          />
        </div>
        <!-- 优惠券 -->
        <div
          v-else
          class="prize-dialog__content-coupon"
        >
          <!-- 免邮券 -->
          <template v-if="couponType === 1">
            <OutlineText
              :text="shareLanguage.SHEIN_KEY_PWA_22801"
              font-size="48"
              class="free-shipping"
            />
          </template>
          <!-- 满减券 -->
          <template v-if="couponType === 2">
            <div class="fill-discount">
              <OutlineText
                :text="discountValue"
                font-size="48"
              />
              <OutlineText
                :text="shareLanguage.SHEIN_KEY_PWA_25739"
                font-size="48"
              />
            </div>
          </template>
          <!-- 折扣券 -->
          <template v-if="couponType === 3">
            <div class="discount-coupon">
              <div class="num">
                <OutlineText
                  :text="discountValue"
                  font-size="96"
                />
                <OutlineText
                  text="%"
                  font-size="48"
                />
              </div>
              <OutlineText
                :text="shareLanguage.SHEIN_KEY_PWA_25739"
                font-size="48"
              />
            </div>
          </template>
          <div class="condition-tip">
            {{ discountCondition }}
          </div>
        </div>
      </div>
      <div
        v-tap="getAnalysisData('2-22-32', prizeReportParams)"
        class="prize-dialog__btn"
        :style="{ backgroundImage: `url(${images.prizeBtn})` }"
        @click="closeDialog"
      >
        {{ shareLanguage.SHEIN_KEY_PWA_25743 }} ({{ countdown }}s)
      </div>
      <div class="prize-dialog__tip">
        {{
          prizeType === 1
            ? shareLanguage.SHEIN_KEY_PWA_25738
            : shareLanguage.SHEIN_KEY_PWA_25737
        }}
      </div>
    </s-dialog>
  </SLazyMount>
</template>
<script>
import { defineComponent } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import OutlineText from './OutlineText.vue'
import { template, preloadImgs } from '@shein/common-function'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'

export default defineComponent({
  name: 'PrizeDialog',
  inject: ['context', 'sceneData', 'extendData', 'shareLanguage'],
  components: {
    SLazyMount,
    SDialog,
    OutlineText,
  },
  directives: {
    tap,
    expose,
  },
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    prizeInfo: {
      type: Object,
      default: () => ({}),
    },
    channel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countdown: 10,
      images,
      prizeType: 1, // 奖品类型 1: 优惠券、2: 积分
      couponType: 0, // 优惠券类型 1: 免邮券、2: 满减券、3: 折扣券
      discountValue: '', // 优惠券折扣值或者满减值
      discountCondition: '', // 优惠券折扣条件
      prizeReportParams: {},
      intersectionObserver: null,
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.prizeType = this.prizeInfo?.prizeType
          this.extendInfo = JSON.parse(this.prizeInfo?.extendInfo || '{}')
          this.getPrizeReportParams()
          this.getCouponType()
          this.getCouponDiscount()
          if (this.channel === 'copyInviteLink') {
            this.handleCountdown()
          } else {
            this.observeCountdown()
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // 预加载背景图
    preloadImgs({
      imgs: [this.images.prizeDialogBg],
    })
  },
  methods: {
    template,
    // 10s倒计时
    handleCountdown() {
      const timer = setInterval(() => {
        if (this.countdown <= 1) {
          clearInterval(timer)
          this.$emit('close')
          return
        }
        this.countdown--
      }, 1000)
    },
    closeDialog() {
      this.$emit('close')
    },
    getCouponType() {
      const { ruleDimension = '', applyFor = '' } = this.extendInfo || {}
      if (applyFor == '5' || applyFor == '6') {
        this.couponType = 1 // 免邮券
        return
      } else {
        if (ruleDimension == '1') this.couponType = 2 // 满减券
        if (ruleDimension == '2' || ruleDimension == '5') this.couponType = 3 // 折扣券
      }
    },
    getCouponDiscount() {
      const { rule = [] } = this.extendInfo || {}
      let discountValue = ''
      // 门槛值
      const min = rule?.[0]?.minAmount?.amountWithSymbol || '0.00'
      if (this.couponType === 2) {
        // 满减券
        discountValue = rule?.[0]?.valueAmount?.amountWithSymbol || '0.00'
      } else if (this.couponType === 3) {
        // 折扣券
        discountValue = Number.parseInt(rule?.[0]?.value || '0')
      }
      this.discountValue = discountValue
      this.discountCondition = this.template(
        min,
        this.shareLanguage.SHEIN_KEY_PWA_25736
      )
    },
    getPrizeReportParams() {
      const { prizeCode = '', prizeData = '' } = this.prizeInfo
      if (this.prizeType === 1) {
        this.prizeReportParams = {
          prize_type: `2_${prizeCode}`, // 埋点：1为积分 2为优惠券
        }
      } else if (this.prizeType === 2) {
        this.prizeReportParams = {
          prize_type: `1_${prizeCode}_${prizeData}`,
        }
      }
    },
    // 监听页面可见状态才开始倒计时
    observeCountdown() {
      document.addEventListener('visibilitychange', this.visibilityStateHandle)
    },
    visibilityStateHandle() {
      if (document.visibilityState === 'visible') {
        document.removeEventListener(
          'visibilitychange',
          this.visibilityStateHandle
        )
        this.handleCountdown()
      }
    },
  },
})
</script>
<style lang="less" scoped>
.prize-dialog {
  :deep(.sui-dialog__wrapper) {
    background: transparent;
    box-shadow: none;
  }
  :deep(.sui-dialog__normal) {
    width: 100%;
    max-width: 100%;
  }
  &__title {
    text-align: center;
    // font-family: 'Roboto';
    font-size: 0.8533rem;
    font-style: italic;
    font-weight: 900;
    text-transform: capitalize;
    background-image: linear-gradient(
      270deg,
      #fff0a1 0%,
      #fff 50.03%,
      #fff0a1 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    margin-bottom: 0.3733rem;
  }
  &__content {
    width: 100%;
    height: 4.96rem;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__content-point,
  &__content-coupon {
    width: 5.3067rem;
    margin: auto;
    position: absolute;
    height: 2.08rem;
  }
  &__content-point {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 0.5867rem;
    .point-text {
      margin-left: 0.16rem;
    }
  }
  &__content-coupon {
    padding: 0.56rem 0.1067rem 0;
    text-align: left;
    // 免邮券
    .free-shipping {
      text-align: left;
    }
    // 满减券
    .fill-discount {
      display: flex;
      align-items: center;
    }
    // 折扣券
    .discount-coupon {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: -0.1333rem;
      margin-bottom: -0.1333rem;
      p {
        line-height: 1;
      }
      .num {
        display: flex;
        align-items: baseline;
      }
    }
    .condition-tip {
      color: #9f4200;
      // font-family: 'SF Pro Text';
      font-size: 0.3733rem;
      font-weight: 700;
      margin-top: 0.1333rem;
    }
  }
  &__btn {
    margin: 0.5333rem auto 0.3733rem;
    color: #fff;
    text-align: center;
    // font-family: 'SF Pro Text';
    font-size: 0.5333rem;
    font-weight: 700;
    height: 1.1467rem;
    width: 4.7733rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &__tip {
    width: 100%;
    padding: 0 .8rem;
    color: #fff;
    text-align: center;
    // font-family: 'Roboto';
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
