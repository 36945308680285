<template>
  <div
    v-expose="getAnalysisData('2-22-27', likeReportParams)"
    class="thumbs-up"
  >
    <div
      v-tap="getAnalysisData('2-22-28', likeReportParams)"
      class="thumbs-up__circle"
      @click="toggleLikeState"
    >
      <div class="thumbs-up__num">
        <span>{{ formatLikeTotal }}</span>
      </div>
      <sui_icon_likes_pink_color
        v-if="isLike"
        size="20"
      />
      <sui_icon_like_completed_28px
        v-if="!isLike"
        size="24"
        color="#fff"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { throttle } from '@shein/common-function'
import { images } from 'public/src/pages/ccc-campaigns/components/common/Icon.js'
import {
  updatePageConfigLikeState,
  getPageConfigLikeData,
} from 'public/src/services/api/ccc'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'
import { sui_icon_likes_pink_color, sui_icon_like_completed_28px } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'ThumbsUp',
  components: {
    sui_icon_likes_pink_color,
    sui_icon_like_completed_28px,
  },
  inject: ['context', 'sceneData', 'extendData'],
  directives: {
    tap,
    expose,
  },
  mixins: [mixin],
  data() {
    return {
      images,
      isLike: false,
      likeTotal: 0,
      curIdentity: this.context?.content?.identity || '',
    }
  },
  computed: {
    formatLikeTotal() {
      const total = Number(this.likeTotal)
      if (total > 1000) {
        const n = Math.round(total / 10) / 100
        return n.toFixed(1) + 'k+'
      }
      return total
    },
    // 点赞埋点参数
    likeReportParams() {
      return {
        like_number: this.likeTotal,
      }
    },
  },
  mounted() {
    this.fetchPageConfigLikeData()
  },
  methods: {
    timeZone() {
      // 时区
      const date = new Date()
      const timeZone = date.getTimezoneOffset() / 60
      return `GMT${timeZone > 0 ? '-' : '+'}${Math.abs(timeZone)}`
    },
    // 获取点赞数据
    async fetchPageConfigLikeData() {
      try {
        const res = await getPageConfigLikeData({
          dataSourceType: 2,
          identifierList: this.curIdentity,
          likeType: 1,
        })
        const likeInfo = res?.data?.[0]?.likeInfo || {}
        const { userLikeStatus = 0, likeTotal = 0 } = likeInfo
        this.isLike = Number(userLikeStatus) === 1
        this.likeTotal = likeTotal
      } catch (error) {
        console.log(error)
      }
    },
    // 点赞上报 & 取消
    toggleLikeState: throttle({
      func: async function () {
        try {
          const isReport = !this.isLike
          // 请求前先改变视觉状态
          this.isLike = !this.isLike
          this.likeTotal = isReport ? this.likeTotal + 1 : this.likeTotal - 1
          const res = await updatePageConfigLikeState({
            dataSourceType: 2,
            identifier: this.curIdentity,
            likeStatus: 1,
            likeType: 1,
            timeZone: this.timeZone(),
            isReport,
          })
          if (res?.code !== '1000') return
          const { userLikeStatus = 0, likeTotal = 0 } = res?.data || {}
          this.isLike = Number(userLikeStatus) === 1
          this.likeTotal = likeTotal
        } catch (error) {
          console.log('error :>> ', error)
        }
      },
      wait: 1000,
    }),
  },
})
</script>
<style lang="less" scoped>
.thumbs-up {
  .flexbox();
  .align-center();
  .pack-center();
  width: 1.7067rem;
  &__num {
    position: absolute;
    border: 1px solid #fff;
    background: linear-gradient(221deg, #ff884a 0%, #fe3274 100%);
    border-radius: 0.5867rem;
    height: 0.4267rem;
    min-width: 0.5333rem;
    padding: 0 0.08rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.1333rem;
    right: -0.24rem;
    text-align: center;
    span {
      font-size: 0.2667rem;
      // font-family: 'SF UI Display';
      font-weight: bold;
      color: #fff;
      height: 100%;
    }
  }
  &__circle {
    position: relative;
    height: 0.96rem;
    width: 0.96rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  // &__icon {
  //   font-size: 0.48rem;
  //   color: #fff;
  // }
}
</style>
