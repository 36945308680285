<template>
  <div
    class="tab-item"
    @click="clickTab"
  >
    <div class="tab-item__icon">
      <BaseImg
        class="tab-item__icon-img"
        :first-screen="true"
        :is-support-webp="true"
        :img-design-width="60"
        :img-src="isCurrentTab ? tabItem.expandData.prevImg.src : tabItem.expandData.nextImg.src"
      />
      <!-- 此img 用于preload icon-->
      <BaseImg
        v-show="false"
        :first-screen="true"
        :is-support-webp="true"
        :img-design-width="60"
        :img-src="isCurrentTab ? tabItem.expandData.nextImg.src : tabItem.expandData.prevImg.src"
      />
    </div>
    <div
      class="tab-item__name"
      :style="{ color: isCurrentTab ? tabItem.expandData.prevColor : tabItem.expandData.nextColor}"
    >
      <span class="tab-item__text">{{ tabItem.expandData.tabMemo }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
export default defineComponent({
  name: 'CccTabItem',
  components: {
    BaseImg
  },
  props: {
    tabItem: {
      type: Object,
      default: () => ({})
    },
    currentTabId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    isCurrentTab() {
      return this.tabItem.identity === this.currentTabId
    },
  },
  mounted() {

  },
  methods: {
    clickTab() {
      this.$emit('clickTab', this.tabItem)
    }
  },
})
</script>
<style lang="less">
.tab-item {
  flex: 1;
  text-align: center;
  padding-bottom: .107rem;
  padding-top: .107rem;
  display: flex;
  flex-direction: column;
  &__icon {
    margin: 0 auto;
    width: .8rem;
    height: .8rem;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
    }
  }
  &__name {
    .font-dpr(.533rem);
    line-height: 1;
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .11rem;
  }
  &__text {
    word-break: break-all;
    .line-camp(2);
  }
}

</style>
